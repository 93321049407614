import React, { useEffect, useState } from 'react'
import { Loader } from '@components';

// import  DynamicHomePage  from "../../index.page";
import DynamicHomePage from "./Home.page";
import { HomeRender } from 'src/pages/dynamicPageCommon/DynamicApi';
import { config } from "@constants";

const HomePage = (): JSX.Element => {

  const [getData, setGetData] = useState([]);

  useEffect(() => {
    homePage();
  }, []);

  const runTimeHome = (e: any) => {
    setGetData(e)
  }

  const homePage = () => {
    if (getData?.length === 0) {
      HomeRender(
        {
          id: config?.CONTENTFUL_SM_HOME_ENTRY_ID,
          runTimeHome: runTimeHome
        }
      )
    }

  }
  return getData?.length ?

    <DynamicHomePage dymanicarrpages={getData} />
    :
    (<div style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', top: 0, left: 0 }}>
      <Loader /> </div>)
}

export default HomePage;
